import React, { useEffect, useCallback } from 'react';
import Box from '@mui/system/Box';
import { motion, useAnimation } from 'framer-motion';

import imgBgPipe from "../../../images/bodkyOk/verticalAnim/bodky1-animQ-vert-w97-h240.png"


const styleSxWrap = ({
  "&.wrapPipes": {
    position: "relative",
    perspective: "500px",
    "& .wrapPipe1, & .wrapPipe2": {
      position: "absolute",
      top: 0,
      width: "inherit",
      height: "inherit",
      perspective: "500px",
      "& .pipe1": {
        perspective: "500px",
        backgroundOrigin: "border-box",
        backgroundBlendMode: 'color-burn',
        borderTopStyle: "solid",
        borderBottomStyle: "solid",
        borderColor: "#0000001f",
        boxShadow: "inset 3px 0px 17px 20px #0000001f",
        width: "inherit",
        height: "inherit",
        transform: "translateZ(100px)",
        borderRadius: "50px",
        cursor: "pointer",
      },
      "& .pipe2": {
        perspective: "500px",
        backgroundOrigin: "border-box",
        borderTopStyle: "solid",
        borderBottomStyle: "solid",
        borderColor: "#0000001f",
        boxShadow: "inset 3px 0px 17px 20px #0000001f",
        width: "inherit",
        height: "inherit",
        transform: "translateZ(100px)",
        borderRadius: "50px",
      },
    },
  }
})



const varPipe1 = ({ startPositionPipe1BgX, startPositionPipe1BgY, endPositionPipe1BgX, endPositionPipe1BgY, anim1DurationS }) => {
  return ({
    initial: false,
    animate: {
      backgroundPositionX: [ startPositionPipe1BgX, endPositionPipe1BgX, startPositionPipe1BgX ],
      backgroundPositionY: [ startPositionPipe1BgY, endPositionPipe1BgY, startPositionPipe1BgY ],
      opacity: [ 0.2, 1.0, 0.2 ],
      transition: {
        duration: anim1DurationS,
        // type: "spring",
        // stiffness: 300,
        // damping: 20,
      },
    },
    exit: {
      opacity: 0.2,
      backgroundPositionX: startPositionPipe1BgX,
      backgroundPositionY: startPositionPipe1BgY,
    },
    whileHover: {
      backgroundPositionX: endPositionPipe1BgX,
      backgroundPositionY: endPositionPipe1BgY,
      opacity: 1.0,
      transition: {
        duration: 5,
        repeat: 1,
        repeatType: "reverse",
      },
    },
  })
}

const varPipe2 = ({ startPositionPipe2BgX, startPositionPipe2BgY, endPositionPipe2BgX, endPositionPipe2BgY, anim2DurationS }) => {
  return ({
    initial: false,
    animate: {
      backgroundPositionX: [ startPositionPipe2BgX, endPositionPipe2BgX, startPositionPipe2BgX ],
      backgroundPositionY: [ startPositionPipe2BgY, endPositionPipe2BgY, startPositionPipe2BgY ],      // visibility: ["visible", "hidden"],
      opacity: [ 0.2, 1.0, 0.2 ],
      transition: {
        duration: anim2DurationS,
        // type: "spring",
        // stiffness: 300,
        // damping: 20,
      },
    },
    exit: {
      opacity: 0.2,
      backgroundPositionX: startPositionPipe2BgX,
      backgroundPositionY: startPositionPipe2BgY,
    },

    whileHover: {
      backgroundPositionX: endPositionPipe2BgX,
      backgroundPositionY: endPositionPipe2BgY,
      opacity: 1.0,
      transition: {
        duration: 5,
        repeat: 1,
        repeatType: "reverse",
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    },
  })
}


const SeparatorMovingImgOnPipe = ({ children, isHorizontal = false, minVerticalHeight = "100vh", imagesBgPipe1 = [ imgBgPipe ], linGradient1_3Colors = [ "#b0bec5", "#b28e593d", "#b0bec5" ], imgBg1Size = "250px 50px", pipe1Diameter = "60px", startPositionPipe1Bg = "left 0% top 0%", startPositionPipe1BgX = "0%", startPositionPipe1BgY = "0%", endPositionPipe1Bg = "right 0% bottom 0%", endPositionPipe1BgX = "100%", endPositionPipe1BgY = "100%", anim1DurationS = "5", stylePipe1 = {}, isPipe2 = true, imagesBgPipe2 = [ imgBgPipe ], linGradient2_3Colors = [], imgBg2Size = "200px 50px", pipe2Diameter = "40px", isPipe2DirectionRev = false, anim2DurationS = "10", betweenPipe12 = "10px", styleWrapPipe2 = {}, stylePipe2 = {} }) => {
  const controlsPipe = useAnimation()
  const runAnimate = useCallback(async () => {
    await controlsPipe.start("animate",
    )
  }, [ controlsPipe ]);

  useEffect(() => {
    if (window) {
      const timerRunAnimate = window.setTimeout(runAnimate, 3000);
      return () => {
        controlsPipe.stop()
        clearTimeout(timerRunAnimate)
      }
    }
  }, [ controlsPipe, runAnimate ])

  const handleTap = async (e, info, prop) => {
    e.preventDefault();
    e.stopPropagation();
    await controlsPipe.start("exit")
    await controlsPipe.start("animate")
  }

  imgBg1Size = isHorizontal ? "250px 550px" : "250px 50px"
  imgBg2Size = isHorizontal ? "250px 550px" : "250px 50px"
  const startPositionPipe2BgX = isPipe2DirectionRev ? endPositionPipe1BgX : startPositionPipe1BgX
  const startPositionPipe2BgY = isPipe2DirectionRev ? endPositionPipe1BgY : startPositionPipe1BgY
  const endPositionPipe2BgX = isPipe2DirectionRev ? startPositionPipe1BgX : endPositionPipe1BgX
  const endPositionPipe2BgY = isPipe2DirectionRev ? startPositionPipe1BgY : endPositionPipe1BgY


  let urlImages1 = "" //= (Array.isArray(imagesBgPipe1) && imagesBgPipe1.length > 0) ? imagesBgPipe1.map((img,i)=>)
  let urlImages2 = ""

  if (Array.isArray(imagesBgPipe1) && imagesBgPipe1.length > 0) {
    urlImages1 = imagesBgPipe1.reduce((acc, curr, i) => {
      return acc = `${acc},url(${curr})`
    }, "").substring(1);
  }
  if (Array.isArray(imagesBgPipe2) && imagesBgPipe2.length > 0) {
    urlImages2 = imagesBgPipe2.reduce((acc, curr, i) => {
      return acc = `${acc},url('${curr}')`
    }, "").substring(1);
  }
  const linGrad1 = (Array.isArray(linGradient1_3Colors) && linGradient1_3Colors.length > 0) ? `linear-gradient(${isHorizontal ? 12 : 45}deg,${linGradient1_3Colors[ 0 ]} 0%, ${linGradient1_3Colors[ 1 ]} 50%, ${linGradient1_3Colors[ 2 ]} 100%)` : ""
  const linGrad2 = (Array.isArray(linGradient2_3Colors) && linGradient2_3Colors.length > 0) ? `linear-gradient(${isHorizontal ? 12 : 45}deg,${linGradient2_3Colors[ 0 ]} 0%, ${linGradient2_3Colors[ 1 ]} 50%, ${linGradient2_3Colors[ 2 ]} 100%)` : ""
  const borderColor1 = linGrad1.length > 0 ? linGradient1_3Colors[ 0 ] : "#0000001f"
  const borderColor2 = linGrad2.length > 0 ? linGradient2_3Colors[ 0 ] : "#0000001f"
  const bgImages1 = imagesBgPipe1.length === 0 ? linGrad1 : linGradient1_3Colors.length > 0 ? `${urlImages1},${linGrad1}` : urlImages1
  const bgImages2 = imagesBgPipe2.length === 0 ? linGrad2 : linGradient2_3Colors.length > 0 ? `${urlImages2},${linGrad2}` : urlImages2
  // const bgSize1 = (isHorizontal && linGradient1_3Colors.length === 0) ? null : imgBg1Size
  const bgSize2 = (isHorizontal && linGradient2_3Colors.length === 0) ? null : imgBg2Size
  return <Box
    className="wrapPipes"
    sx={{
      ...styleSxWrap,
      height: isHorizontal ? pipe1Diameter : "100%",
      minHeight: isHorizontal ? "unset" : minVerticalHeight,
      width: isHorizontal ? "100%" : pipe1Diameter,
      my: isHorizontal ? "unset" : "5%",
    }}
  >
    <div
      className="wrapPipe1"
      style={{
        zIndex: 1,
      }}
      key="1">
      <Box
        component={motion.div}
        className="pipe1"
        sx={{
          ...stylePipe1,
          backgroundImage: bgImages1,
          backgroundBlendMode: "multiply",
          backgroundSize: imgBg1Size, // "250px 50px",
          borderColor: borderColor1,
          backgroundRepeat: "round",
          opacity: 0.2,
          backgroundPositionX: startPositionPipe1BgX,
          backgroundPositionY: startPositionPipe1BgY,
        }}
        variants={varPipe1({ startPositionPipe1BgX, startPositionPipe1BgY, endPositionPipe1BgX, endPositionPipe1BgY, anim1DurationS })}
        initial={false}
        animate={controlsPipe}
        whileHover="whileHover"
        onTap={(e, info) => handleTap(e, info)}
        key="varPipe1"
      >
        {children}
      </Box>
    </div>;
    {isPipe2 && (
      <div
        className="wrapPipe2"
        style={{
          ...styleWrapPipe2,
          top: isHorizontal ? betweenPipe12 : 0,
          left: isHorizontal ? 0 : betweenPipe12,
          height: isHorizontal ? pipe2Diameter : "100%",
          width: isHorizontal ? "100%" : pipe2Diameter,
        }}
        key="2">
        <Box
          component={motion.div}
          className="pipe2"

          sx={{
            ...stylePipe2,
            backgroundImage: bgImages2,
            backgroundRepeat: "round",
            backgroundSize: bgSize2,//"200px 50px",
            borderColor: borderColor2,

            backgroundPositionX: startPositionPipe2BgX,
            backgroundPositionY: startPositionPipe2BgY,
            opacity: 0.2,
          }}

          variants={varPipe2({ startPositionPipe2BgX, startPositionPipe2BgY, endPositionPipe2BgX, endPositionPipe2BgY, anim2DurationS })}
          initial={false}
          animate={controlsPipe}
          whileHover="whileHover"
          key="varPipe2"
        >
          {children}
        </Box>
      </div>)}
  </Box>
};


export default SeparatorMovingImgOnPipe
